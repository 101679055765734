export class FilterSearch {
    searchInput;
    filtersItems = [];
    noResultDiv;

    constructor(searchInput) {
        this.searchInput = searchInput;

        const filtersItemsNodes = document.querySelectorAll(this.searchInput.dataset.searchFor);
        this.filtersItems = Array.from(filtersItemsNodes).map(item => {
            return {
                node: item,
                label: item.querySelector('label').textContent,
            }
        });

        this.noResultDiv = document.createElement("div");
        this.noResultDiv.textContent = 'Aucun résultat.';
        this.noResultDiv.style.display = 'none';
        filtersItemsNodes.item(0).insertAdjacentElement('beforebegin', this.noResultDiv);

        this.searchInput.addEventListener('input', (e) => {
            this.filtersItems.forEach(item => {
                item.label.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                    ? item.node.style.display = 'block'
                    : item.node.style.display = 'none';
            });

            this.filtersItems.every(item => item.node.style.display === 'none')
                ? this.noResultDiv.style.display = 'block'
                : this.noResultDiv.style.display = 'none';
        });
    }
}