export class PreviewProductImages {
    mainElement;
    gallery;
    imageItems;
    figure;
    figureImg;

    constructor(mainElement) {
        this.mainElement = mainElement;
        this.gallery = this.mainElement.querySelector('.product-gallery');
        this.imageItems = this.mainElement.querySelectorAll('.product-gallery-item');
        this.figure = this.mainElement.querySelector('.product-image-zoom');
        this.figureImg = this.figure.querySelector('img');

        this.gallery.addEventListener('click', event => {
            if (event.target.classList.contains('product-gallery-item')) {
                this.showImage(event.target);
            }
        });

        this.figure.addEventListener('mousemove', (e) => this.zoom(e));
    }

    showImage(button) {
        this.figure.style.backgroundImage = `url(${button.dataset.img})`;
        this.figureImg.src = button.dataset.img;
        this.imageItems.forEach(element => {
            element.classList.remove('active');
        });
        button.classList.add('active');
    }

    zoom(event) {
        const x = event.offsetX/this.figure.offsetWidth*100;
        const y = event.offsetY/this.figure.offsetHeight*100;
        this.figure.style.backgroundPosition = x + '% ' + y + '%';
    }
}