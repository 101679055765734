export class QuantityControl {
    control;
    increaser;
    decreaser;
    input;

    constructor(control) {
        this.control = control;
        this.increaser = this.control.querySelector('.quantity-increaser');
        this.decreaser = this.control.querySelector('.quantity-decreaser');
        this.input = this.control.querySelector('input');

        this.increaser.addEventListener('click', this.increase.bind(this));
        this.decreaser.addEventListener('click', this.decrease.bind(this));

        this.input.addEventListener('input', this.onInputChange.bind(this));
    }

    decrease() {
        this.input.value--;

        this.decreaser.disabled = this.input.value < 1;

        if (this.increaser.dataset.max) {
            this.increaser.disabled = this.input.value >= this.increaser.dataset.max;
        }
    }

    increase() {
        this.input.value++;

        this.decreaser.disabled = this.input.value <= 0;

        if (this.increaser.dataset.max) {
            this.increaser.disabled = this.input.value >= this.increaser.dataset.max;
        }
    }

    onInputChange() {
        this.increaser.disabled = false;

        if (this.input.value < 0) {
            this.input.value = 0;
        }

        if (this.input.max) {
            const max = +this.input.max;

            if (this.input.value >= max) {
                this.input.value = max;
                this.increaser.disabled = true;
            }
        }

        this.decreaser.disabled = this.input.value <= 0;
    }
}